import React from "react";
import { graphql } from 'gatsby';
import { Container } from "react-bootstrap";
import Header from "../components/common/header";
import CardGrid from "../components/grid/card-grid";
import Footer from "../components/common/footer";

class Tag extends React.Component {
    render() {
        let tag_name = this.props.pageContext.tag;
        let posts = this.props.data.allMarkdownRemark.edges;

        return (
            <div>
                <Header pageName={tag_name.charAt(0).toUpperCase() + tag_name.slice(1)} />
                <Container>
                    <div className={"section-title"}>
                        <h2>{tag_name.charAt(0).toUpperCase() + tag_name.slice(1)}</h2>
                    </div>
                    <CardGrid posts={posts} postsPerPage={12} />
                </Container>
                <Footer />
            </div>
        )
    }
}

export default Tag


export const pageQuery = graphql`
query TagPage($tag: String) {
    allMarkdownRemark(
        limit: 1000
        filter: { 
            fields: { 
                tags: { 
                    in: [$tag] 
                } 
            } 
        }
        sort: {
            fields: [frontmatter___date, frontmatter___title],
            order: DESC
        }
    ) 
    {
        totalCount
        edges {
            node {
                fields {
                    slug
                    tags
                }
                excerpt
                timeToRead
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    title
                    tags
                    author
                    description
                    featuredImage {
                        id
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }                
            }
        }
    }
}
`
